import Joi from 'joi';
// @ts-ignore
import cssVars from '../../css/colors.scss';

const hotTableMixins = {
    methods: {
        $_hotTableMixins_setMarkProperties(ele, text, color) {
            const element = ele;
            setSharedCellProperties(element, text);
            setCellBackgroundColor(element, color);
            element.style.fontWeight = 'bold';
            // element.style.fontSize = '1.4rem';
            element.style.textAlign = 'center';
            element.style.verticalAlign = 'middle';
        },
        $_hotTableMixins_setGradebookMarkProperties(ele, text, color, gradebookMode, headerRow, pointsPossible) {
            const element = ele;
            setSharedCellProperties(element, text);
            setCellBackgroundColor(element, color);
            element.style.fontWeight = 'bold';
            // element.style.fontSize = '1.4rem';
            if (gradebookMode === 'Percentages') {

                element.innerHTML = `<div style="display: flex; position: relative; height: 100%; align-items: center; justify-content: center;">${text}<span style="position: absolute; right: 0; top: 0; font-size: 1.1rem; color: grey;">%</span></div>`;
            } else if (headerRow) {
                element.innerHTML = `<div style="display: flex; position: relative; height: 100%; align-items: center; justify-content: center;">${text}</div>`;
            } else {

                element.innerHTML = `<div style="display: flex; position: relative; height: 100%; align-items: center; justify-content: center;">${text}<span style="position: absolute; right: 0; top: 0; font-size: 1rem; color: grey;">/ ${pointsPossible}</span></div>`;
            }
        },
        $_hotTableMixins_setGradebookHeaderProperties(ele, assignment) {
            const element = ele;
            setCellBackgroundColor(element, assignment.color);
            element.style.fontWeight = 'bold';
            // element.style.fontSize = '1.4rem';

            element.innerHTML = `<div style="display: flex; position: relative; height: 100%; align-items: center; justify-content: center; font-size: 1.5rem;">/ ${assignment.maxPoints}<span style="position: absolute; right: 0; top: 0; font-size: 1rem; color: grey;">${assignment.courseWorkDomain === 'Local' ? 'SG' : 'G'}</span></div>`;
        },
        $_hotTableMixins_setGradeCategoryProperties(ele, categoryName, color) {
            const element = ele;
            setSharedCellProperties(element, categoryName);
            setCellBackgroundColor(element, color);
            // element.style.fontWeight = 'bold';
            element.style.textAlign = 'center';
            element.style.verticalAlign = 'middle';
        },
        $_hotTableMixins_setMarkingPeriodProperties(ele, color) {
            const element = ele;
            setCellBackgroundColor(element, color);
            // element.style.fontWeight = 'bold';
            element.style.textAlign = 'center';
            element.style.verticalAlign = 'middle';
        },
        $_hotTableMixins_setCellColor(td, color) {
            const element = td;
            setCellColor(element, color);
        },
        $_hotTableMixins_setCellBackgroundColor(td, value, color) {
            setSharedCellProperties(td, value);
            setCellBackgroundColor(td, color);
        },
        $_hotTableMixins_getColorForCategory(gradeTemplateCategoryId, gradeTemplateId) {
            const gradeTemplate = this.$store.state.database.gradeTemplates.find((t) => t.gradeTemplateId == gradeTemplateId);
            if (!gradeTemplate) return null;
            const gradeTemplateCategory = gradeTemplate.categories.find((c) => c.gradeTemplateCategoryId == gradeTemplateCategoryId) || null;
            if (!gradeTemplateCategory) return null;
            return gradeTemplateCategory.categoryColor;
        },
        $_hotTableMixins_getColorForMarkingPeriod(markingPeriod) {
            const digit = (markingPeriod || '').slice(-1); // last char
            return digit ? `color${digit}` : 'color0';
        },
        $_hotTableMixins_getColorForMark(rawMark, gradeTemplate, pointsPossible) {
            if (!gradeTemplate) return '';

            const isNumeric = !Number.isNaN(parseFloat(rawMark));
            if (isNumeric) {
                const pointsEarned = parseFloat(rawMark);
                const percentEarned = (pointsPossible > 0) ? round((pointsEarned / pointsPossible), 4) : 0;
                const roundedPercent = Math.round(percentEarned * 100.0);
                const grade = gradeTemplate.marks.find((mark) => `${rawMark}` == mark.gradeTemplateMark);
                const roundedGrade = gradeTemplate.scale.grades.find((scale) => (roundedPercent >= scale.minGrade && roundedPercent <= scale.maxGrade));
                if (percentEarned > 1) return 'Blue'; // Extra credit
                return grade?.gradeTemplateMarkColor || roundedGrade?.color || '';
            }

            const markExists = gradeTemplate.marks.find((m) => rawMark && m.gradeTemplateMark == rawMark) || null;
            if (markExists) return markExists.gradeTemplateMarkColor;
            return '';
        },
        $_hotTableMixins_getColorForGradebookMark(rawMark, gradeTemplate, gradebookMode, pointsPossible) {
            if (!gradeTemplate) return '';

            const isNumeric = !Number.isNaN(parseFloat(rawMark));
            const { grades } = gradeTemplate.scale;

            if (isNumeric) { // Use Average Scale for Percentage Mode, Grade Marks for Points Mode
                let pointsEarned = parseFloat(rawMark);
                let percentEarned = (pointsPossible > 0) ? round((pointsEarned / pointsPossible), 4) : 0;
                let roundedPercent = Math.round(percentEarned * 100.0);
                const grade = gradeTemplate.marks.find((mark) => `${rawMark}` == mark.gradeTemplateMark);
                let roundedGrade = (grades || []).find((scale) => (roundedPercent >= scale.minGrade && roundedPercent <= scale.maxGrade));

                if (gradebookMode === 'Percentages') {
                    percentEarned = pointsEarned / 100;
                    pointsEarned = round((percentEarned * pointsPossible), 1);
                    roundedPercent = Math.round(percentEarned * 100.0);
                    roundedGrade = (grades || []).find((scale) => (roundedPercent >= scale.minGrade && roundedPercent <= scale.maxGrade));
                    if (percentEarned > 1) return 'Blue'; // Extra credit
                    return roundedGrade?.color || '';
                }

                if (percentEarned > 1) return 'Blue'; // Extra credit
                return grade?.gradeTemplateMarkColor || roundedGrade?.color || '';
            }

            const markExists = gradeTemplate.marks.find((m) => rawMark && m.gradeTemplateMark == rawMark) || null;
            if (markExists) return markExists.gradeTemplateMarkColor;
            return '';
        },
    },
};

function setSharedCellProperties(ele, value) {
    const element = ele;

    element.innerText = value;
    return element;

    // const img = document.createElement('img');
    // img.src = value;
    // img.addEventListener('mousedown', (event) => {
    //     event.preventDefault();
    // });
    // td.appendChild(img);
}

function setCellColor(ele, color) {
    const element = ele;
    const hex = cssVars[color || 'Grey'];

    element.style.color = hex;
    return element;
}

function setCellBackgroundColor(ele, color) {
    const element = ele;
    const hex = cssVars[color || 'Grey'];
    const { r, g, b } = hexToRgb(hex);

    element.style.backgroundColor = `rgba(${r},${g},${b},.1)`;
    element.style.color = hex;
    return element;
}

function hexToRgb(h) {
    // https://stackoverflow.com/a/5624139
    let hex = h;
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } : null;
}

function round(value, decimals) {
    // @ts-ignore
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export {
    hotTableMixins as default,
    setCellColor,
    setCellBackgroundColor,
};
